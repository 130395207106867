
type StorageKey = 'authToken';

const keys: Record<StorageKey, string> = {
  authToken: '@token',
};

export const storage = {
  setToken: (token: string) => {
    localStorage.setItem(keys.authToken, token);
  },
  getToken: () => {
    return localStorage.getItem(keys.authToken);
  },
  clearToken: () => {
    return localStorage.removeItem(keys.authToken);
  },
};

import {DocumentNode} from 'graphql';
import {gql} from '@apollo/client';
import fragments from './fragments';

export type QueryName =
  | 'CURRENT_USER';

export const queries: Record<QueryName, DocumentNode> = {
  CURRENT_USER: gql`
    query CurrentUser {
      user {
        ...User
      }
    }
    ${fragments.USER}
  `,
};

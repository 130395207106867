import { DocumentNode } from 'graphql';
import { gql } from '@apollo/client';

export type FragmentName =
  | 'USER';


const USER = gql`
  fragment User on User {
    id
    email
  }
`;

const fragments: Record<FragmentName, DocumentNode> = {
  USER,
};

export default fragments;

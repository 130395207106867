import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {setContext} from '@apollo/client/link/context';
import {storage} from './storage';

console.log(`Using ${process.env.REACT_APP_ENDPOINT} for requests`);

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_ENDPOINT}/graphql`,
});

const error = onError(({response, graphQLErrors, networkError}) => {
  if (graphQLErrors) {
    [...graphQLErrors].forEach(err => {
      console.log(err);
      if (err.message === 'UNAUTHENTICATED') {
        storage.clearToken();
        console.log('cleared');
      }
    });
  }

  if (response?.errors) {
    [...response?.errors].forEach(err => {
      if (err.message === 'Unauthenticated') {
        storage.clearToken(); // remove the token
      }
    });
  }
});

const authLink = setContext(async (_, {headers}) => {
  // get the authentication token from local storage if it exists
  const token = await storage.getToken();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token || '',
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(error).concat(httpLink),
  cache: new InMemoryCache(),
});

import React from 'react'

const ErrorPage = () => {
  return (
    <div>
      404 not found
    </div>
  )
}

export default ErrorPage

import { redirect } from 'react-router-dom';
import {client} from './apollo';
import {storage} from './storage';

export const auth = {
  login: (token: string) => {
    storage.setToken(token);
  },
  logout: () => {
    redirect('/get-started');
    storage.clearToken();
    client.resetStore();
  },
};

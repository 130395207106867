import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from "react-router-dom";
import {ApolloProvider, useQuery} from '@apollo/client';
import {client} from './utils/apollo';
import Root from './layouts/Root';
import './index.css';
import reportWebVitals from './reportWebVitals';

// pages
import GetStartedPage from './pages/GetStartedPage';
import HomePage from './pages/HomePage';
import ErrorPage from './pages/ErrorPage';
import AuthenticatePage from './pages/AuthenticatePage';
import { queries } from './data';

const Guest = () => {
  const { loading, data } = useQuery(queries.CURRENT_USER);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (data.user) {
    return <Navigate to="/" />
  }

  return <Outlet />;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Guest />,
        children: [
          {
            path: "/get-started",
            element: <GetStartedPage />,
          },
          {
            path: "/authenticate",
            element: <AuthenticatePage />
          },
        ]
      },
      {
        path: "/",
        element: <HomePage />,
      },
      
      {
        path: "/connect",
        element: <div>Link webflow</div>,
      },
    ]
  },

]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <RouterProvider router={router} />
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

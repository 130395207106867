import React from 'react'
import { Link } from 'react-router-dom';
import { queries, useQuery } from '../data'
import { auth } from '../utils/auth';

const NavBar = () => {
  const { loading, data } = useQuery(queries.CURRENT_USER);

  return (
    <div style={{ padding: 16, display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid rgba(0, 0, 0, 0.05)'}}>
      <Link to="/">Otto</Link>
      {loading ? null : data?.user ? <a href="#logout" onClick={auth.logout}>Logout</a> : <Link to="/get-started">Get started</Link>}
    </div>
  );

}

export default NavBar

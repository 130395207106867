import {DocumentNode} from 'graphql';
import {gql} from '@apollo/client';
import fragments from './fragments';

export type MutationName =
  | 'LOGIN_OR_SIGNUP'
  | 'AUTH';

export const mutations: Record<MutationName, DocumentNode> = {
  LOGIN_OR_SIGNUP: gql`
    mutation LoginOrSignup($input: LoginOrSignupInput!) {
      loginOrSignup(input: $input) {
        sent
      }
    }
  `,

  AUTH: gql`
    mutation Auth($input: AuthInput!) {
      auth(input: $input) {
        user {
          ...User
        }
        accessToken
      }
    }
    ${fragments.USER}
  `,
};

import React, { useEffect } from 'react'
import { redirect, useNavigate, useSearchParams } from "react-router-dom";
import { mutations, useMutation } from '../data';
import { auth } from '../utils/auth';

const AuthenticatePage = () => {
  const [params, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [authenticate, { loading }] = useMutation(mutations.AUTH, {
    onCompleted: (data) => {
      auth.login(data.auth.accessToken);
    },
    onError: (error) => {
      console.log({ error })
      alert('Something went wrong, try again.');
      redirect('/get-started');
    }
  });

  useEffect(() => {
    const token = params.get('token');

    if (token) {
      authenticate({ variables: { input: { token } } })
      setSearchParams({});
    } else {
      navigate("/get-started")
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  if (loading) {
    return <div>Loading...</div>;
  }
  
  return null;
}

export default AuthenticatePage

import React, { FormEvent, useState} from 'react'
import { mutations, useMutation } from '../data';

const GetStartedPage = () => {
  const [email, setEmail] = useState('');
  const [sent, setSent] = useState(false);

  const [loginOrSignup, { loading }] = useMutation(mutations.LOGIN_OR_SIGNUP, {
    onCompleted: (data) => {
      setSent(data.loginOrSignup.sent);;
    }
  })

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    loginOrSignup({ variables: { input: { email }} });
  }

  if (sent) {
    return <h3>Check your email for a magic link</h3>
  }

  return (
    <div>
      <h3>Enter email</h3>
      <form onSubmit={handleSubmit}>
        <input value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" disabled={loading} />
        <button type="submit" disabled={loading}>Continue</button>
      </form>
    </div>
  )
}

export default GetStartedPage

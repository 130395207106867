import React from 'react'
import { Outlet } from 'react-router-dom'
import NavBar from './NavBar'

const Root = () => {
  return (
    <div>
      <NavBar />
      <div style={{ padding: 16 }}>
        <Outlet />
      </div>
    </div>
  )
}

export default Root
